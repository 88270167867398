<template>
  <div>
    <el-card class="box-card">
      <div class="orderHeader">
        <div class="orderDetail">
          <div class="orderMoney">当前可提现订单总额：</div>
          <div class="money">￥{{ incomeData.settleSumAmount }}</div>
          <div
            v-if="superAdminDetail.superAdmin && bankDetail.bankNumber == null"
            class="bankCard"
            @click="goBankBind"
          >
            去绑定银行卡
          </div>
        </div>
        <div>
          <el-button size="mini" @click="withdrawal">提现申请记录</el-button>
        </div>
      </div>
      <div class="explain">
        <div class="title">提现说明：</div>
        <div>1.提现仅支持待结算的订单</div>
        <div>2.仅支持提现至银行卡,银行卡账号必须为学堂绑定的银行卡</div>
        <div>3.单日最大可提现额度为2万元</div>
        <div>
          4.工作日当日12:00之前提现的金额,1-7个工作日内安排打款,工作日当日12:00之后提现的金额,次日开始,1-7个工作日安排打款。节假日顺延
        </div>
      </div>
      <div class="code">
        申请金额：
        <el-input
          v-model="money"
          placeholder="请输入申请金额"
          class="input-width"
          clearable
        ></el-input>
        元
      </div>
      <el-button type="primary" size="mini" class="withdrawalBtn" @click="handleSubmit"
        >确定提现</el-button
      >
    </el-card>
    <el-dialog
      :close-on-click-modal="false"
      width="30%"
      :visible.sync="dialogVisible"
      title="确定提现"
    >
      <div class="identity">
        <div class="cardNews">
          <div>提现金额：{{ money }}</div>
          <div>开户银行：{{ bankDetail.bankBranch }}</div>
          <div>银行卡号：{{ bankDetail.bankNumber }}</div>
        </div>
        <div class="submit">
          <el-button type="primary" size="medium" @click="dialogSubmit">确定</el-button>
          <el-button size="medium" class="cancel" @click="dialogVisible = false">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getAccountsBankNew, getSearchSuperAdmin } from '@/api/college'
import { getIncomeShow } from '@/api/finance'
import to from 'await-to'
export default {
  data() {
    return {
      money: '',
      superAdminDetail: {},
      bankDetail: {},
      incomeData: {},
      dialogVisible: false,
    }
  },
  created() {
    this.getAccountsBankNewData()
    this.getSearchSuperAdminData()
    this.getIncomeShowData()
  },
  methods: {
    async getAccountsBankNewData() {
      const [res, err] = await to(getAccountsBankNew({}))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.bankDetail = res.data
    },
    async getSearchSuperAdminData() {
      const [res, err] = await to(getSearchSuperAdmin())
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.superAdminDetail = res.data
    },
    async getIncomeShowData() {
      const [res, err] = await to(getIncomeShow())
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.incomeData = res.data
    },
    goBankBind() {
      this.$router.push('/college/setBankCard')
    },
    handleSubmit() {
      if (this.money == '') {
        this.$message({ type: 'warning', message: '请输入提现金额' })
      } else if (!this.bankDetail.bankNumber) {
        this.$message({ type: 'warning', message: '请先去绑定你的银行卡' })
      } else if (this.money > this.incomeData.settleSumAmount) {
        this.$message({ type: 'warning', message: '你的金额不足，请充值' })
      } else {
        this.dialogVisible = true
      }
    },
    dialogSubmit() {
      this.$router.push('withdrawalProgress')
    },
    withdrawal() {
      this.$router.push('withdrawalRecord')
    },
  },
}
</script>

<style scoped lang="scss">
.orderHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  .orderDetail {
    display: flex;
    align-items: center;
    .orderMoney {
      font-size: 16px;
      color: #333;
    }
    .money {
      color: #f8341e;
      font-size: 20px;
      font-weight: 500;
      margin-right: 40px;
    }
    .bankCard {
      color: #ff7b33;
      font-size: 16px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.explain {
  font-size: 12px;
  line-height: 20px;
  color: #606266;
  background: #fff5f0;
  padding: 20px 0 20px 20px;
  margin-bottom: 30px;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  .title {
    margin-bottom: 30px;
  }
}
.code {
  font-size: 14px;
  color: #606266;
}
.withdrawalBtn {
  margin-left: 90px;
  margin-top: 20px;
}
.identity {
  .cardNews {
    padding-left: 50px;
    line-height: 30px;
    font-size: 14px;
    color: #606266;
  }
  .submit {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    .cancel {
      margin-left: 48px;
    }
  }
}
.input-width {
  width: 238px;
  height: 40px;
}
</style>
